.benef_profil {
  max-width: 540px;
  width: 100%;

  margin: 0 auto;
  padding-left: 5px;
  padding-right: 5px;
  &-goBackicon {
    transform: rotate(180deg);
  }

  &-header {
    margin-bottom: 15px;
    margin-top: 10px;

    display: flex;
    justify-content: space-between;
  }

  &-title {
    margin-bottom: 1em;
  }
}
