.not-found {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &-container {
    max-width: 320px;
  }

  &-title {
    font-size: 2em;
    line-height: 2em;

    span {
      font-size: 3em;
    }
  }
}
