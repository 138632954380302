@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;500;800&display=swap");

.block-field {
  margin-bottom: 10px !important;

  &.filter {
    max-width: 300px;
  }
}
.CtSelect {
  .CTreactSelect__placeholder {
    font-weight: 900;
    color: rgba($color: #000000, $alpha: 0.6);
  }
  .CTreactSelect__control {
    min-height: 38px;
    background-color: transparent;
    border-color: rgba($color: #000000, $alpha: 0.2);

    &--menu-is-open,
    &--is-focused,
    &:hover {
      border-color: rgba($color: #009633, $alpha: 0.2);
      box-shadow: 0 0 0 1px #009633;
    }
  }
  .CTreactSelect__multi-value {
    background-color: #6cac95;
  }
  .CTreactSelect__multi-value__label {
    color: #ececec;
  }
}

.image_container {
  height: 150px;
  width: 150px;

  border: 1px solid #009633;

  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
