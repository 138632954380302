.login {
  display: flex;
  justify-content: center;
  flex-direction: column;

  align-items: center;
  height: 100vh;

  min-width: 320px;
  background-color: #6cad95;

  img {
    width: 200px;
  }

  &-form {
    max-width: 320px;
    text-align: center;

    padding: 0 0.5em;
  }
}
